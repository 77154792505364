import React, { useEffect, useState, useCallback } from 'react';
import useApiRequest from '../../components/fetch/useApiRequest';
import Daily from './Daily'; // Daily 컴포넌트 임포트 추가
import TableComponent from './TableComponent';
import CardList from './CardListComponent';
import BarChart from './BarChart';

function Monthly() {
    const token = localStorage.getItem('access_token');
    const endpoint = '/statistics/monthly';
    const { getApiRequest } = useApiRequest();
    const [monthlyData, setMonthlyData] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [isDailyVisible, setIsDailyVisible] = useState(false); // Daily 컴포넌트 표시 상태
    const [selectedMonth, setSelectedMonth] = useState(null); // 선택한 월 상태 추가
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 모바일 상태 추가
    const [isChart, setIsChart] = useState(false); // 모바일 상태 추가

    // 화면 크기 변경 시 모바일 상태 업데이트
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 상위 컴포넌트에서 데이터 조회 함수 정의
    const fetchStatistics = useCallback(async () => {
        try {
            setLoading(true);
            await getApiRequest(
                endpoint,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                (data) => {
                    setMonthlyData(data);
                },
                (errorMessage) => {
                    console.error('에러 메시지:', errorMessage);
                }
            );
        } catch (error) {
            console.error('주문 목록 요청 중 오류가 발생했습니다:', error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        fetchStatistics();
    }, [fetchStatistics]);

    // 합계 계산 함수
    const calculateTotals = (monthlyData) => {
        return monthlyData.reduce(
            (acc, curr) => {
                acc.totalAmount += curr.total_amount || 0;
                acc.totalFund += curr.total_fund || 0;
                acc.totalRevenue += curr.revenue || 0;
                acc.totalPartnerRevenue += curr.partner_revenue || 0;
                return acc;
            },
            { totalAmount: 0, totalFund: 0, totalRevenue: 0, totalPartnerRevenue: 0 }
        );
    };

    const totals = calculateTotals(monthlyData); // 합계 계산

    const months = Array.from({ length: 12 }, (_, i) => ({
        index: `${i + 1}월`,
        total_amount: 0,
        total_fund: 0,
        revenue: 0,
        partner_revenue: 0,
    }));

    monthlyData.forEach(month => {
        const monthIndex = parseInt(month.month) - 1; // 월 인덱스 계산
        months[monthIndex] = {
            index: `${monthIndex + 1}월`,
            total_amount: month.total_amount || 0,
            total_fund: month.total_fund || 0,
            revenue: month.revenue || 0,
            partner_revenue: month.partner_revenue || 0,
        };
    });

    // 월을 클릭했을 때 호출되는 함수
    const handleRowClick = (month) => {
        const year = new Date().getFullYear();
        const monthNumber = parseInt(month.replace(/\D/g, ''), 10);
        setSelectedMonth({ year, monthNumber }); // 선택한 월과 연도 저장
        setIsDailyVisible(true); // Daily 컴포넌트 표시
    };

    return (
        <>
        {!isDailyVisible && !isChart && (
            <div className='partner-list-container'>
                <h1 className="partner-list-title">Monthly Statistics</h1>
                <div className='daily-button'>
                    <button onClick={() => setIsChart(true)}>
                        차트보기
                    </button>
                </div>
                {/* isChart가 true일 때 CardList 또는 TableComponent를 숨김 */}
                {
                    isMobile
                        ? <CardList datalist={months} totals={totals} totalTitle={'2024년'} onRowClick={handleRowClick} />
                        : <TableComponent datalist={months} totals={totals} totalTitle={'2024년'} onRowClick={handleRowClick} />
                }
                {/* BarChart 컴포넌트 추가 */}
            </div>)}
        {isChart && (
            <BarChart datalist={months} chartTitle={'2024년'} setIsChart={setIsChart}/>
        )}
        {/* Daily 컴포넌트 표시 */}
        {isDailyVisible && (
            <Daily 
                year={selectedMonth?.year}
                month={selectedMonth?.monthNumber}
                setIsDailyVisible={setIsDailyVisible}
            />
        )}
        </>
    );
}

export default Monthly;
