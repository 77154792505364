import TransactionList from './TransactionList'
import useApiRequest from '../fetch/useApiRequest'; // API 요청 훅
import { useEffect, useState } from 'react';
import { formatAmount } from '../utils/FormatChange'

const OrderRequest = ({ address, setAddress, token, user }) => {
    const { getApiRequest, postApiRequest } = useApiRequest();
    const [orderAmount, setOrderAmount] = useState(''); // 주문 금액 상태
    const [funingdBalance, setFundingBalance] = useState(0)
    const [tradingdBalance, setTradingdBalance] = useState(0)

    const handleOrderRequest = async () => {
        if (!orderAmount || !address) { // 주소 체크 추가
          alert('주문 금액과 주소를 입력해주세요.');
          return;
        }
    
        try {
          await postApiRequest(
            '/admin/admin/order', // 주문 API 엔드포인트
            {
              amount: orderAmount,
              user_id: user.id,
              address: address // 주소 추가
            }, // 요청 본문에 금액 포함
            {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            (data) => {
              alert(data.message);
              setOrderAmount('')
            },
            (error) => {
              alert(error);
            }
          );
    
        } catch (error) {
          console.error('주문 요청 중 오류가 발생했습니다:', error);
        }
      };

    const handleBalanceRequest = async () => {
        try {
          await getApiRequest(
            '/admin/balance',
            {},
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            (data) => {
                console.log(data)
                setFundingBalance(data.funding_balance)
                setTradingdBalance(data.trading_balance)
            },
            (errorMessage) => {
                console.log(errorMessage);
            }
        );
      
          } catch (error) {
            console.error('주문 요청 중 오류가 발생했습니다:', error);
          }
    }

    useEffect(() => {
        handleBalanceRequest()
         // eslint-disable-next-line
    }, [])

    return (
        <>
            <h2 className="partner-detail-subtitle">OKX 내부전송(admin만 보여짐)</h2>
            <div className="partner-detail-form">
              <div className="partner-info">
                <div className="partner-info-row full-width">
                  <span>트레이딩 보유 자산:</span>
                  <span>{formatAmount(tradingdBalance, 0)} USDT</span>
                </div>
                <div className="partner-info-row full-width">
                  <span>펀딩 보유자산:</span>
                  <span>{formatAmount(funingdBalance, 0)} USDT</span>
                </div>
              </div>
            </div>
            <div className="partner-detail-form">
              <input
                type="number"
                value={orderAmount}
                onChange={(e) => setOrderAmount(e.target.value)}
                placeholder="주문금액"
              />
              <select value={address} onChange={(e) => setAddress(e.target.value)}> {/* 주소 셀렉트 추가 */}
                <option value="">주소 선택(이메일)</option>
                <option value="kwonseyoung.work@gmail.com">kwonseyoung.work@gmail.com</option>
                <option value="pbs23th1111@gmail.com">pbs23th1111@gmail.com</option>
                {/* 추가 주소 옵션들 */}
              </select>
              <button onClick={handleOrderRequest}>주문요청</button>
            </div>
            {/* 주문 목록 표시 */}
            <h2 className="partner-detail-subtitle">내부전송 목록</h2>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <TransactionList token={token} endpoint={`/admin/transaction`} />
            </div>
          </>
    )
}
    
export default OrderRequest;