// src/components/statistics/Table.js
import React from 'react';
import { formatAmount } from '../utils/FormatChange';

const TableComponent = ({ datalist, totals, totalTitle, onRowClick = null }) => {
    
    return (
        <table className="partner-list-table">
            <thead>
                <tr>
                    <th>일시</th>
                    <th>구매수량(USDT)</th>
                    <th>거래금액(원)</th>
                    <th>회사수익(원)</th>
                    <th>파트너수익(원)</th>
                </tr>
            </thead>
            <tbody>
                {/* 합계 표시 */}
                <tr className="total-row">
                    <td className="right-align">{totalTitle} 합계</td>
                    <td className="right-align">{formatAmount(totals.totalAmount, 0)} USDT</td>
                    <td className="right-align">{formatAmount(totals.totalFund, 0)} 원</td>
                    <td className="right-align">{formatAmount(totals.totalRevenue, 0)} 원</td>
                    <td className="right-align">{formatAmount(totals.totalPartnerRevenue, 0)} 원</td>
                </tr>

                {/* 월별 데이터 표시 */}
                {datalist.map((data) => (
                    <tr key={data.index} onClick={() => onRowClick && onRowClick(data.index)}>
                        <td className="right-align">{data.index}</td>
                        <td className="right-align">{formatAmount(data.total_amount, 0)} USDT</td>
                        <td className="right-align">{formatAmount(data.total_fund, 0)} 원</td>
                        <td className="right-align">{formatAmount(data.revenue, 0)} 원</td>
                        <td className="right-align">{formatAmount(data.partner_revenue, 0)} 원</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableComponent;
