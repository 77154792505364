import OrderRequest from "../components/admincomponents/OrderRequest";
import "../styles/UserDetail.css";
import "../styles/Dashboard.css";
import useUserData from "../components/customhook/useUserData";
import { useCallback, useEffect, useState } from "react";
import MidiumCardBox from "../components/common/MidiumCardBox";
import CoinoneDeposit from "../components/table/CoinoneDepot";
import OrderList from "../components/admincomponents/OrderList";

const UserDetails = () => {
  const token = localStorage.getItem("access_token");
  const [user, setUserdata] = useState();
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(""); // 주소 상태 추가
  const [selectedMonth, setSelectedMonth] = useState([]); // 선택된 월 상태 추가

  const { getuserdata } = useUserData();

  const copyToClipboard = async (text) => {
    try {
      const referral_link = `https://dashboards.bonegowallet.com/dashboard-signup?referral=${text}`;
      await navigator.clipboard.writeText(referral_link);
      alert("파트너 회원가입 추천링크 복사됨!");
    } catch (err) {
      alert("복사 실패");
    }
  };

  const bonegoWalletCopyToClipboard = async (text) => {
    try {
      const referral_link = `https://exchange.bonegowallet.com?referral=${text}`;
      await navigator.clipboard.writeText(referral_link);
      alert("보내고 월렛 추천링크 복사됨!");
    } catch (err) {
      alert("복사 실패");
    }
  };

  const getUser = useCallback(async (month) => {
    const response = await getuserdata(token, month);
    if (response) {
      setUserdata(response.data);
      const registerDate = new Date(response.data.register_dt);
      const currentDate = new Date();
      const months = [];
      for (let m = registerDate.getMonth(); m <= currentDate.getMonth(); m++) {
        months.push(m + 1); // 월은 0부터 시작하므로 +1
      }
      setSelectedMonth(months.reverse());
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [token]); // `getuserdata`와 `token`에 의존

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleSelectMonth = (e) => {
    const month = e.target.value;
    getUser(month); // 선택된 값을 상태에 저장
  };

  if (!user) return <div>Loading...</div>;
  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1 className="dashboard-title">Home</h1>
        </div>
        <div className="dashboard-name-header">
          <h1 className="dashboard-name">{user.username}</h1>
        </div>
        <div className="dashboard-card">
          <div className="dashboard-info">
            <div className="dashboard-info-row">
              <span>이메일:</span> <span>{user.email}</span>
            </div>
            <div
              className="dashboard-info-row"
              onClick={() => copyToClipboard(user.referral_code)}
              style={{ cursor: "pointer" }}
            >
              <span>파트너 추천코드(클릭시 복사):</span>{" "}
              <span>{user.referral_code}</span>
            </div>
            <div
              className="dashboard-info-row full-width"
              onClick={() => bonegoWalletCopyToClipboard(user.referral_code)}
              style={{ cursor: "pointer" }}
            >
              <span>보내고월렛 추천링크(클릭시 복사):</span>
              <span>
                https://exchange.bonegowallet.com?referral={user.referral_code}
              </span>
            </div>
          </div>
        </div>

        {user.user_role === "admin" ? ( // 유저 역할이 admin일 때만 주문 섹션 표시
          <OrderRequest
            address={address}
            setAddress={setAddress}
            token={token}
            user={user}
          />
        ) : (
          <>
            <h2 className="dashboard-subtitle">최근 요약</h2>
            <div style={{ width: "100%", padding: "15px" }}>
              <select
                style={{ width: "100%" }}
                onChange={handleSelectMonth} // 선택된 값을 상태에 저장하는 함수로 변경
                //   value={selectedMonth} // 선택된 값이 상태와 일치하도록 설정
              >
                {selectedMonth.map((month) => (
                  <option key={month} value={month}>
                    {month}월
                  </option>
                ))}
              </select>
            </div>
            <div className="medium-card">
              <MidiumCardBox
                title="월 수익"
                data={[
                  { value: user.summary_data?.order_count ?? 0, label: "건" },
                  { value: user.summary_data?.total_amount ?? 0, label: "KRW" },
                ]}
              />
              <MidiumCardBox
                title="월 수당"
                data={[
                  { value: user.allowance_data?.order_count ?? 0, label: "건" },
                  { value: user.allowance_data?.allowance ?? 0, label: "USDT" },
                ]}
              />
              <MidiumCardBox
                title="보유 금액"
                data={[
                  {
                    value: user.balance?.available ?? 0,
                    label: user.balance?.currency ?? "KRW",
                  },
                ]}
              />
              <MidiumCardBox
                title="사용가능 금액"
                data={[
                  {
                    value: user.available ?? 0,
                    label: user.balance?.currency ?? "KRW",
                  },
                ]}
              />
            </div>
            <h2 className="dashboard-subtitle">코인원 입금정보</h2>
            <CoinoneDeposit datalist={user.deposit} />
            <h2 className="partner-detail-subtitle">주문목록</h2>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <OrderList token={token} endpoint={`/orders`} userBool={false} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserDetails;
