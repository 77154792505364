import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useApiRequest from "../components/fetch/useApiRequest"; // API 요청 훅
import "../styles/PartnerDetail.css";
import { FaArrowLeft } from "react-icons/fa";
import OrderList from "../components/admincomponents/OrderList";
import MidiumCardBox from "../components/common/MidiumCardBox";
import CoinoneDeposit from "../components/table/CoinoneDepot";

const PartnerDetail = () => {
  const location = useLocation();
  const navigate = useNavigate(); // 이전 화면으로 돌아가기 위해 useNavigate 훅 사용
  const { partner } = location.state || {};
  const [orderAmount, setOrderAmount] = useState(""); // 주문 금액 상태
  const { getApiRequest, postApiRequest } = useApiRequest();
  const token = localStorage.getItem("access_token");
  const [user, setUser] = useState();
  const [selectedMonth, setSelectedMonth] = useState([]); // 선택된 월 상태 추가

  const hangleGetUserData = async (month = null) => {
    try {
      await getApiRequest(
        "/admin/user", // 주문 API 엔드포인트
        {
          user_id: partner.id,
          month: month
        }, // 요청 본문에 금액 포함
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        (data) => {
          setUser(data);
          // 월 옵션 생성
          const registerDate = new Date(data.register_dt);
          const currentDate = new Date();
          const months = [];
          for (
            let m = registerDate.getMonth();
            m <= currentDate.getMonth();
            m++
          ) {
            months.push(m + 1); // 월은 0부터 시작하므로 +1
          }
          setSelectedMonth(months.reverse());
        },
        (error) => {
          alert(error);
        }
      );
    } catch (error) {
      console.error("주문 요청 중 오류가 발생했습니다:", error);
    }
  };

  const handleOrderRequest = async () => {
    if (!orderAmount) {
      alert("주문 금액을 입력해주세요.");
      return;
    }

    try {
      await postApiRequest(
        "/admin/partner/order", // 주문 API 엔드포인트
        {
          amount: orderAmount,
          user_id: partner.id,
        }, // 요청 본문에 금액 포함
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        (data) => {
          alert(data.message);
          setOrderAmount("");
        },
        (error) => {
          alert(error);
        }
      );
    } catch (error) {
      console.error("주문 요청 중 오류가 발생했습니다:", error);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  useEffect(() => {
    const fetchData = async () => {
      if (partner) {
        await hangleGetUserData();
      }
    };

    fetchData(); // 비동기 함수 호출
    // eslint-disable-next-line
  }, [partner]); // partner가 변경될 때마다 실행

  const handleSelectMonth = (e) => {
    const month = e.target.value;
    hangleGetUserData(month); // 선택된 값을 상태에 저장
  };

  if (!partner) {
    return (
      <div className="error-message">파트너 정보를 불러올 수 없습니다.</div>
    );
  }

  if (!user) {
    return (
      <div className="error-message">파트너 정보를 불러올 수 없습니다.</div>
    );
  }

  console.log(selectedMonth);

  return (
    <>
      <div className="partner-detail-container">
        <div className="partner-detail-header">
          <FaArrowLeft onClick={handleGoBack} className="back-icon" />{" "}
          {/* 이전 화면으로 돌아가기 아이콘 */}
          <h1 className="partner-detail-title">Partner Information</h1>
        </div>
        <div className="partner-name-header">
          <h2 className="partner-name">{partner.username}</h2>
        </div>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row">
              <span>이메일:</span> <span>{partner.email}</span>
            </div>
            <div className="partner-info-row">
              <span>추천코드:</span> <span>{partner.referral_code}</span>
            </div>
            <div className="partner-info-row">
              <span>전화번호:</span>{" "}
              <span>{partner.partner?.phone_number}</span>
            </div>
            <div className="partner-info-row">
              <span>은행:</span> <span>{partner.partner?.bank_name}</span>
            </div>
            <div className="partner-info-row">
              <span>계좌번호:</span>{" "}
              <span>{partner.partner?.bank_account_number}</span>
            </div>
            <div className="partner-info-row">
              <span>예금주:</span>{" "}
              <span>{partner.partner?.account_holder_name}</span>
            </div>
            <div className="partner-info-row full-width">
              <span>오류 :</span> <span>{partner.partner?.remark}</span>
            </div>
          </div>
        </div>
        <h2 className="dashboard-subtitle">최근 요약</h2>
        <div style={{ width: '100%', padding: '15px'}}>
        <select style={{ width: '100%'}}
          onChange={handleSelectMonth} // 선택된 값을 상태에 저장하는 함수로 변경
        //   value={selectedMonth} // 선택된 값이 상태와 일치하도록 설정
        >
          {selectedMonth.map((month) => (
            <option key={month} value={month}>
              {month}월
            </option>
          ))}
        </select>
        </div>
        <div className="medium-card">
          <MidiumCardBox
            title="월 수익"
            data={[
              { value: user.summary_data?.order_count ?? 0, label: "건" },
              { value: user.summary_data?.total_amount ?? 0, label: "KRW" },
            ]}
          />
          <MidiumCardBox
            title="월 수당"
            data={[
              { value: user.allowance_data?.order_count ?? 0, label: "건" },
              { value: user.allowance_data?.allowance ?? 0, label: "USDT" },
            ]}
          />
          <MidiumCardBox
            title="보유 금액"
            data={[{ value: user.balance?.available ?? 0, label: "KRW" }]}
          />
          <MidiumCardBox
            title="사용가능 금액"
            data={[{ value: user.available ?? 0, label: "KRW" }]}
          />
        </div>
        <h2 className="dashboard-subtitle">코인원 입금정보</h2>
        <CoinoneDeposit datalist={user.deposit} />

        <h2 className="partner-detail-subtitle">주문</h2>

        <div className="partner-detail-form">
          <input
            type="number"
            value={orderAmount}
            onChange={(e) => setOrderAmount(e.target.value)}
            placeholder="주문금액"
          />
          <button onClick={handleOrderRequest}>주문요청</button>
        </div>

        <h2 className="partner-detail-subtitle">주문목록</h2>
        {/* 주문 목록 표시 */}
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <OrderList
            token={token}
            endpoint={`/admin/partner/detail/${partner.id}/orders`}
            userBool={false}
          />
        </div>
      </div>
    </>
  );
};

export default PartnerDetail;
