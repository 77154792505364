import {AiOutlineClose} from "react-icons/ai";
import '../../styles/OrderDetail.css';
import '../../styles/UserOrderDetail.css';
import {formatAmount} from "../utils/FormatChange";
import useApiRequest from "../fetch/useApiRequest";

const UserOrderDetail = ({order, handleSetIsOpen, fetchOrders}) => {
    const {postApiRequest} = useApiRequest();
    const token = localStorage.getItem('access_token');

    const handleChangeStatus = async (status) => {
        try {
            await postApiRequest(
                '/admin/consumer/order/update', // 주문 API 엔드포인트
                {
                    status: status,
                    id: order.id

                }, // 요청 본문에 금액 포함
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                (data) => {
                    alert(data.message);
                    handleClose()
                },
                (error) => {
                    alert(error);
                }
            );

        } catch (error) {
            console.error('요청 중 오류가 발생했습니다:', error);
        }
    }

    const handleClose = () => {
        handleSetIsOpen(false)
        fetchOrders()
    };

    if (!order) {
        return <div className="error-message">주문 정보를 불러올 수 없습니다.</div>;
    }

    return (
        <div className="user_order_main">
            <div className="order-detail-container">
                <div className="order-detail-header">
                    <AiOutlineClose onClick={handleClose} className="back-icon"/>
                    <h1 className="order-detail-title">주문 상세 정보</h1>
                </div>

                {/* 주문 기본 정보 */}
                <div className="order-detail-card">
                    <h2 className="card-title">주문 정보</h2>
                    <div className="order-info">
                        <div className="order-info-row">
                            <span>사용자(한글이름):</span> <span>{order.name_kor}</span>
                        </div>
                        <div className="order-info-row">
                            <span>사용자(영문이름):</span> <span>{order.name_eng}</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문 ID:</span> <span>{order.id}</span>
                        </div>
                        <div className="order-info-row">
                            <span>전화번호:</span> <span>{order.phone}</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문수량:</span> <span>{formatAmount(order.amount, 4)} USDT</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문금액:</span> <span>{formatAmount(order.total_fund, 0)} 원</span>
                        </div>
                        <div className="order-info-row">
                            <span>지갑종류:</span> <span>{order.wallet_type}</span>
                        </div>
                        <div className="order-info-row">
                            <span>네트워크:</span> <span>{order.network}</span>
                        </div>
                        <div className="order-info-row full-width">
                            <span>지갑주소:</span> <span>{order.wallet_address}</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문 상태:</span> <span>{order.status}</span>
                        </div>
                        <div className="order-info-row">
                            <span>접속IP:</span> <span>{order.ip_address}</span>
                        </div>
                        <div className="order-info-row">
                            <span>추천인:</span> <span>{order.referral}</span>
                        </div>
                        <div className="order-info-row">
                            <span>추천코드:</span> <span>{order.referral_code}</span>
                        </div>
                        <div className="order-info-row">
                            <span>생성 날짜:</span> <span>{new Date(order.created_at).toLocaleString()}</span>
                        </div>
                        <div className="order-info-row">
                            <span>수정 날짜:</span> <span>{new Date(order.updated_at).toLocaleString()}</span>
                        </div>
                        {order.status === '신청' && (
                            <>
                                <button className="canceled" onClick={() => handleChangeStatus('취소')}>취소</button>
                                <button className="complated" onClick={() => handleChangeStatus('완료')}>완료</button>
                            </>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default UserOrderDetail;
