import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from "./router/Login";
import Signup from "./router/Signup";
import PartnerSignup from "./router/PartnerSignup";
import Dashboard from './router/Dashboard';
import { UserProvider } from './UserContext';
import Layout from "./components/common/Layout";
import AdminPage from "./router/AdminPage";
import PartnerDetail from "./router/PartnerDetail"; // PartnerDetail 컴포넌트 추가
import ProtectedRoute from './components/ProtectedRoute';
import PartnerList from "./router/PartnerList";
import OrderDetail from './router/OrderDetail.jsx';
import AdminOrderList from './router/AdminOrderList.jsx';
import UserDetails from './router/UserDetail.jsx';
import Statistics from './router/Statistics.jsx';
import TermsOfServicePage from './components/TermsOfServicePage.jsx';
import UserOrderList from "./router/UserOrderList";

const RouterSetup = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <ProtectedRoute>
                    <Layout />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: "",
                    element: <Dashboard />,
                    children: [
                        {
                            path: "",
                            element: <UserDetails />,
                        },
                        {
                            path: "order/:orderId",
                            element: <OrderDetail />,
                        },
                    ]
                },
                {
                    path: "terms",
                    element: <TermsOfServicePage />,
                },
                {
                    path: "admin",
                    element: (
                        <ProtectedRoute requiredRole="admin">
                            <AdminPage /> {/* AdminPage 하위에서 Admin을 렌더링 */}
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: "", // AdminPage 하위 경로로 추가
                            element: <PartnerList />,
                        },
                        {
                            path: "partner/:id", // AdminPage 하위 경로로 추가
                            element: <PartnerDetail />,
                        },
                        {
                            path: "order/:orderId", // AdminPage 하위 경로로 추가
                            element: <OrderDetail />,
                        },
                        {
                            path: "orders", // AdminPage 하위 경로로 추가
                            element: <AdminOrderList />,
                        },
                        {
                            path: "statistics", // AdminPage 하위 경로로 추가
                            element: <Statistics />,
                        },
                                                {
                            path: "history", // AdminPage 하위 경로로 추가
                            element: <UserOrderList />,
                        },
                    ]
                },
            ]
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/signup",
            element: <Signup />
        },
        {
            path: "/partner-signup",
            element: <PartnerSignup />
        },
    ]);

    return <RouterProvider router={router} />;
};

const App = () => {
    return (
        <UserProvider>
            <RouterSetup />
        </UserProvider>
    );
};

export default App;
