// src/components/statistics/CardList.js
import React from 'react';
import { formatAmount } from '../utils/FormatChange';

const Card = ({ data, onRowClick }) => {
    console.log(data)
    return (
        <div className="partner-card" onClick={() => onRowClick && onRowClick(data.index)}>
            <h3>{data.index}</h3>
            <p>구매수량: {formatAmount(data.total_amount, 0)} USDT</p>
            <p>거래금액: {formatAmount(data.total_fund, 0)} 원</p>
            <p>회사수익: {formatAmount(data.revenue, 0)} 원</p>
            <p>파트너수익: {formatAmount(data.partner_revenue, 0)} 원</p>
        </div>
    );
};

const CardList = ({ datalist, totals, totalTitle, onRowClick = null }) => {
    console.log(datalist)
    return (
        <div className="partner-card-list">
            {/* 합계 표시 */}
            <div className="partner-card total-card">
                <h3>{totalTitle} 합계</h3>
                <p>구매수량: {formatAmount(totals.totalAmount, 0)} USDT</p>
                <p>거래금액: {formatAmount(totals.totalFund, 0)} 원</p>
                <p>회사수익: {formatAmount(totals.totalRevenue, 0)} 원</p>
                <p>파트너수익: {formatAmount(totals.totalPartnerRevenue, 0)} 원</p>
            </div>
            {datalist.map((data) => (
                <Card
                    key={data.index}
                    data={data}
                    onRowClick={onRowClick}
                />
            ))}
        </div>
    );
};

export default CardList;
