import { formatAmount, formatDate } from '../utils/FormatChange'

const CoinoneDeposit = ({ datalist }) => {
    const colums=["일시", "상태", "금액", "이용가능여부"]
    const comment = '24시간 이후 이용가능'
    return (
        <table className="order-list-table">
            <thead>
                <tr>
                    {colums.map((colum, index) => (
                        <th key={index}>{colum}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {datalist.map((data, index) => (
                    <tr key={index} className="total-row">
                        <td>{formatDate(data.created_at)}</td>
                        <td>{data.status}</td>
                        <td>{formatAmount(data.amount)} KRW</td>
                        <td>{data.status === "DEPOSIT_COMPLETE" && comment}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default CoinoneDeposit;
