import React from 'react';
import {formatAmount, formatDate} from '../utils/FormatChange'

const UserOrderCard = ({orders, handleSetIsOpen, handelsetOrder}) => {
    const veiwOrderDetail = (order) => {
        handleSetIsOpen(true)
        handelsetOrder(order)
    }
    return (
        <div className="order-card-list">
            {orders.length > 0 ? (
                orders.map(order => (
                    <div
                        key={order.id}
                        className="order-card clickable"
                        onClick={() => veiwOrderDetail(order)}
                    >
                        <p className="order-date">ID.: {order.id}</p>
                        <p className="order-date">사용자: {order.name_kor}</p>
                        <p className="order-date">주문 날짜: {formatDate(order.created_at)}</p>
                        <p className="order-date">주문 번호: {order.order_id}</p>
                        <p className="order-amount">주문 수량: {formatAmount(order.amount)} USDT</p>
                        <p className="order-amount">주문 금액: {formatAmount(order.total_fund)} 원</p>
                        <p className="order-status">상태: <span className={`status-${order.status.toLowerCase()}`}>
                            {order.status}
                            </span></p>
                    </div>
                ))
            ) : (
                <p>주문 내역이 없습니다.</p>
            )}
        </div>
    );
};

export default UserOrderCard;
