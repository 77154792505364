import React, {useCallback, useEffect, useState} from 'react';
import useApiRequest from '../../components/fetch/useApiRequest'; // API 요청 훅
import useMediaQuery from './../customhook/useMediaQuery';
import '../../styles/OrderList.css'; // 테이블 스타일링을 위한 CSS 불러오기
import Loading from '../common/Loading';
import TransactionTable from './Transactiontable';
import TransactionCard from './TransactionCard';


const TransactionList = ({token, endpoint}) => {
    const {getApiRequest, loading} = useApiRequest();
    const [orders, setOrders] = useState([]);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const fetchOrders = useCallback(async () => {
        try {
            await getApiRequest(
                endpoint, // 주문 목록을 가져올 API 엔드포인트
                {},
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            (data) =>
            {
                setOrders(data);
            }
        ,
            (errorMessage) => {
                console.log(errorMessage);
            }
            );
        } catch (error) {
            console.error('주문 목록 요청 중 오류가 발생했습니다:', error);
            // eslint-disable-next-line
        }}, [token, endpoint]);

    useEffect(() => {
        fetchOrders();

    // eslint-disable-next-line
    }, [])
    return (
        <div className="order-list-container">
            {loading ? <Loading /> : (
                isMobile ? (
                    <TransactionCard orders={orders}/>
                ) : <TransactionTable orders={orders}/>
            )}
        </div>
    );
};

export default TransactionList;
