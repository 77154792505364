import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApiRequest from "../components/fetch/useApiRequest";
import { FaArrowLeft } from "react-icons/fa";
import '../styles/OrderDetail.css';

const OrderDetail = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState(null);
    const { getApiRequest } = useApiRequest();
    const token = localStorage.getItem('access_token');

    // 원화 금액 포맷팅 함수
    const formatKRW = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        return amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // USDT 금액 포맷팅 함수
    const formatUSDT = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(4).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };

    const getOkxDepositStatus = (status) => {
        const statusMap = {
            '0': '확인 대기 중',
            '1': '입금 처리됨',
            '2': '입금 성공',
            '8': '해당 암호화폐 일시 입금 중단으로 대기 중',
            '11': '주소 블랙리스트 일치',
            '12': '계정 또는 입금 동결',
            '13': '서브 계정 입금 차단',
            '14': 'KYC 한도 초과'
        };
        return statusMap[status] || `알 수 없는 상태 (${status})`;
    };

    const getOkxWithdrawalStatus = (status) => {
        const statusMap = {
            // Stage 1: 출금 대기 중
            '10': '송금 대기 중',
            '0': '출금 대기 중',
            '4': '수동 검토 대기 중',
            '5': '수동 검토 대기 중',
            '6': '수동 검토 대기 중',
            '8': '수동 검토 대기 중',
            '9': '수동 검토 대기 중',
            '12': '수동 검토 대기 중',
            '7': '승인됨',

            // Stage 2: 출금 진행 중
            '1': '블록체인에 트랜잭션 브로드캐스팅 중',
            '15': '트랜잭션 검증 대기 중',
            '16': '현지 법규로 인해 출금이 최대 24시간 소요될 수 있음',
            '-3': '취소 중',

            // Final stage
            '-2': '취소됨',
            '-1': '실패',
            '2': '성공'
        };
        return statusMap[status] || `알 수 없는 상태 (${status})`;
    };

    useEffect(() => {
        const fetchOrderDetail = async () => {
            try {
                await getApiRequest(
                    `/orders/${orderId}`,
                    {},
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    (data) => {
                        setOrder(data);
                    },
                    (error) => {
                        console.error('주문 상세 정보를 불러오는 데 실패했습니다:', error);
                    }
                );
            } catch (error) {
                console.error('주문 상세 정보 요청 중 오류가 발생했습니다:', error);
            }
        };

        fetchOrderDetail();
        const intervalId = setInterval(fetchOrderDetail, 5000); // 5초마다 갱신

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
         // eslint-disable-next-line
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    if (!order) {
        return <div className="error-message">주문 정보를 불러올 수 없습니다.</div>;
    }


    return (
        <>
            <div className="order-detail-container">
                <div className="order-detail-header">
                    <FaArrowLeft onClick={handleGoBack} className="back-icon" />
                    <h1 className="order-detail-title">주문 상세 정보</h1>
                </div>

                {/* 주문 기본 정보 */}
                <div className="order-detail-card">
                    <h2 className="card-title">주문 정보</h2>
                    <div className="order-info">
                        <div className="order-info-row">
                            <span>주문 ID:</span> <span>{order.id}</span>
                        </div>
                        <div className="order-info-row">
                            <span>사용자:</span> <span>{order.user?.username}</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문 금액:</span> <span>{formatKRW(order.amount)} 원</span>
                        </div>
                        <div className="order-info-row">
                            <span>주문 상태:</span> <span>{order.status}</span>
                        </div>
                        <div className="order-info-row">
                            <span>생성 날짜:</span> <span>{new Date(order.created_at).toLocaleString()}</span>
                        </div>
                        <div className="order-info-row">
                            <span>수정 날짜:</span> <span>{new Date(order.updated_at).toLocaleString()}</span>
                        </div>
                        <div className="order-info-row full-width">
                            <span>오류 :</span> <span>{order.remark}</span>
                        </div>
                    </div>
                </div>

                {/* Coinone 주문 정보 */}
                {order.coinone_order && (
                    <div className="order-detail-card">
                        <h2 className="card-title">Coinone 주문 정보</h2>
                        <div className="order-info">
                            <div className="order-info-row full-width">
                                <span>Coinone 주문 ID:</span> <span>{order.coinone_order?.coinone_order_id}</span>
                            </div>
                            <div className="order-info-row">
                                <span>수수료:</span> <span>{formatUSDT(order.coinone_order?.fee)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>평균 체결 가격:</span> <span>{formatKRW(order.coinone_order?.average_executed_price)} 원</span>
                            </div>
                            <div className="order-info-row">
                                <span>체결 수량:</span> <span>{formatUSDT(order.coinone_order?.executed_qty)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>거래 금액:</span> <span>{formatKRW(order.coinone_order?.traded_amount)} 원</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>상태:</span> <span>{order.coinone_order.status}</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* Coinone 출금 정보 */}
                {order.coinone_withdrawal && (
                    <div className="order-detail-card">
                        <h2 className="card-title">Coinone 출금 정보</h2>
                        <div className="order-info">
                            <div className="order-info-row full-width">
                                <span>거래 ID:</span> <span>{order.coinone_withdrawal?.transaction_id}</span>
                            </div>
                            <div className="order-info-row">
                                <span>금액:</span> <span>{formatUSDT(order.coinone_withdrawal?.amount)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>상태:</span> <span>{order.coinone_withdrawal?.status}</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>트랜잭션 ID:</span> <span>{order.coinone_withdrawal?.txid}</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* OKX 입금 정보 */}
                {order.okx_deposit && (
                    <div className="order-detail-card">
                        <h2 className="card-title">OKX 입금 정보</h2>
                        <div className="order-info">
                            <div className="order-info-row">
                                <span>입금 ID:</span> <span>{order.okx_deposit?.deposit_id}</span>
                            </div>
                            <div className="order-info-row">
                                <span>금액:</span> <span>{formatUSDT(order.okx_deposit?.amount)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>통화:</span> <span>{order.okx_deposit?.currency}</span>
                            </div>
                            <div className="order-info-row">
                                <span>상태:</span> <span>{getOkxDepositStatus(order.okx_deposit?.state)}</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>트랜잭션 ID:</span> <span>{order.okx_deposit?.tx_id}</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* OKX 출금 정보 */}
                {order.okx_withdrawal && (
                    <div className="order-detail-card">
                        <h2 className="card-title">OKX 출금 정보</h2>
                        <div className="order-info">
                            <div className="order-info-row">
                                <span>출금 ID:</span> <span>{order.okx_withdrawal?.wd_id}</span>
                            </div>
                            <div className="order-info-row">
                                <span>통화:</span> <span>{order.okx_withdrawal?.ccy}</span>
                            </div>
                            <div className="order-info-row">
                                <span>수량:</span> <span>{formatUSDT(order.okx_withdrawal?.amt)} USDT</span>
                            </div>
                            <div className="order-info-row">
                                <span>상태:</span> <span>{getOkxWithdrawalStatus(order.okx_withdrawal?.state)}</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>출금 주소:</span> <span>{order.okx_withdrawal?.from_address}</span>
                            </div>
                            <div className="order-info-row full-width">
                                <span>수신 주소:</span> <span>{order.okx_withdrawal?.to_address}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default OrderDetail;
