import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; // chart.js에서 registerables를 가져옵니다.
import '../../styles/BarChart.css'

// Chart.js의 모든 구성 요소를 등록합니다.
Chart.register(...registerables);

const BarChart = ({ datalist, chartTitle, setIsChart }) => {
    const data = {
        labels: datalist.map(item => item.index), // 날짜를 레이블로 사용
        datasets: [
            {
                label: 'Total Fund',
                data: datalist.map(item => item.total_fund), // total_fund 데이터를 사용
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };
    return (
            <div className='chart-container'>
                <h1 className="chart-title">{chartTitle} Chart</h1>
                <div className='chart-button'>
                    <button onClick={() => setIsChart(false)}>
                        창닫기
                    </button>
                </div>
                <Bar 
                data={data} 
                options={{
                    responsive: true, // 화면 크기에 따라 자동 조정
                    maintainAspectRatio: true, // 비율 유지
                }} 
            />
            </div>
    );
};

export default BarChart;
