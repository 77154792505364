import React from 'react';
import OrderList from '../components/admincomponents/OrderList';

const AdminOrderList = () => {
    const token = localStorage.getItem('access_token');
    const endpoint = '/admin/orders';
    return (
        <div className="partner-detail-container" style={{ height: '80vh', overflowY: 'auto'}}>
            <h1 className="partner-list-title">Order Information</h1>
            <OrderList token={token} endpoint={endpoint} userBool={true} />
        </div>
    );
};

export default AdminOrderList;

