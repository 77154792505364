import React from 'react';
import { formatDate } from '../utils/FormatChange'

const TransactionTable = ({ orders }) => {
  return (
    <table className="order-list-table">
      <thead>
        <tr>
          <th>주문 날짜</th>
          <th>주문 금액</th>
          <th>상태</th>
          <th>주소</th>
          {/* <th>상세보기</th> */}
        </tr>
      </thead>
      <tbody>
        {orders.length > 0 ? (
          orders.map(order => (
            <tr key={order.id}>
              <td>{formatDate(order.created_at)}</td>
              <td>{order.amount.toLocaleString()} USDT</td>
              <td>{order.status}</td>
              <td>{order.address}</td>
              {/* <td>
                <button className="detail-button" onClick={() => onDetailView(order.id)}>
                  상세보기
                </button>
              </td> */}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">주문 내역이 없습니다.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionTable;
