import React from 'react';
import '../../styles/OrderCard.css';
import { formatDate } from '../utils/FormatChange'

const TransactionCard = ({ orders }) => {
  return (
    <div className="order-card-list">
      {orders.length > 0 ? (
        orders.map(order => (
          <div
            key={order.id}
            className="order-card clickable"
          >
            <p className="order-date">주문 날짜: {formatDate(order.created_at)}</p>
            <p className="order-amount">주문 금액: {order.amount.toLocaleString()} USDT</p>
            <p className="order-status">상태: {order.status}</p>
            <p className="order-status">주소: {order.address}</p>
          </div>
        ))
      ) : (
        <p>주문 내역이 없습니다.</p>
      )}
    </div>
  );
};

export default TransactionCard;
