import React, { memo, useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import '../../styles/NavBar.css';
import logo from '../../assets/logo.svg';
import { UserContext } from "../../UserContext";
import WebSocketComponent from "../WebSocket";

const NavBar = memo(() => {
    const { user, logout } = useContext(UserContext);
    const [state, setState] = useState(false);
    const location = useLocation();

    const handleMenuClick = () => {
        setState(false); // 메뉴 클릭 시 모바일 메뉴를 닫음
    };

    const isAdminActive = location.pathname.startsWith('/admin') && location.pathname !== '/admin/orders' && location.pathname !== '/admin/statistics' && location.pathname !== '/admin/history';;

    return (
        <>
            <nav>
                <NavLink to="/">
                    <img src={logo} alt="Logo" />
                </NavLink>
                <div>
                    <ul id="navbar" className={state ? "navbar active" : "navbar"}>
                        <li>
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={handleMenuClick}
                                end
                            >
                                HOME
                            </NavLink>
                        </li>
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="/admin"
                                    className={({ isActive }) => (isActive || isAdminActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                    end
                                >
                                    ADMIN
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="/admin/orders"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    ORDERS
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="/admin/statistics"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    STATISTICS
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="/admin/history"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    ORDERHISTORY
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="https://partners.bonegowallet.com/"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    TYPE1
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="https://partners-ex.bonegowallet.com/"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    TYPE2
                                </NavLink>
                            </li>
                        )}
                        {user.user_role === "admin" && (
                            <li>
                                <NavLink
                                    to="https://partners-ox.bonegowallet.com/"
                                    className={({ isActive }) => (isActive ? "active" : "")}
                                    onClick={handleMenuClick}
                                >
                                    TYPE3
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink
                                to="/terms"
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={handleMenuClick}
                            >
                                TERMS
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/login"
                                onClick={() => {
                                    logout();
                                    handleMenuClick();
                                }}
                            >
                                LOGOUT
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div id="mobile">
                    <i
                        id="bar"
                        className={state ? "fas fa-times" : "fas fa-bars"}
                        onClick={() => setState(!state)}
                    ></i>
                </div>
            </nav>
            {user?.id === 1 ? <WebSocketComponent userId={1} /> : null}
        </>
    );
});

export default NavBar;
