import { useState } from 'react';
import axios from 'axios';

const useUserData = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getuserdata = async (token, month=null) => {
        setLoading(true);
        setError(null);
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const url = month ? `${BASE_URL}/users/me?month=${month}` : `${BASE_URL}/users/me`;


        try {
            const response = await axios.get(url,  {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                
            });
            return response;
        } catch (err) {
            setError('데이터를 가져오는데 실패했습니다.');
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { getuserdata, loading, error };
};

export default useUserData;
