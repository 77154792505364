import '../../styles/Loading.css';  // CSS 파일을 임포트

const Loading = ({ isFullScreen = false }) => {
  return (
    <div className={`loading-container ${isFullScreen ? 'full-screen' : ''}`}>
      <div className="loading-overlay">
        <div className="loading-text">로딩중...</div>
      </div>
    </div>
  );
};

export default Loading;
