// src/Pagination.js
import React from 'react';
import '../../styles/pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange, maxPageButtons }) => {
    const handleNextPage = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handlePageClick = (page) => {
        if (page !== currentPage) onPageChange(page);
    };

    // 현재 페이지에 따라 표시할 페이지 범위를 동적으로 계산
    const startPage = Math.max(
        1,
        currentPage - Math.floor(maxPageButtons / 2)
    );
    const endPage = Math.min(
        totalPages,
        startPage + maxPageButtons - 1
    );

    const pageNumbers = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
    );

    return (
        <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
            </button>
            <div className="page-numbers">
                {startPage > 1 && (
                    <button onClick={() => handlePageClick(1)}>1</button>
                )}
                {startPage > 2 && <span>...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span>...</span>}
                {endPage < totalPages && (
                    <button onClick={() => handlePageClick(totalPages)}>
                        {totalPages}
                    </button>
                )}
            </div>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
            </button>
        </div>
    );
};

export default Pagination;
