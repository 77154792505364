import React, { useEffect, useState, useCallback } from 'react';
import useApiRequest from '../../components/fetch/useApiRequest';
import '../../styles/Daily.css';
import CardList from './CardListComponent';
import TableComponent from './TableComponent';
import BarChart from './BarChart';

function Daily({ year, month, setIsDailyVisible }) {
    const token = localStorage.getItem('access_token');
    const endpoint = '/statistics/daily';
    const { getApiRequest } = useApiRequest();
    const [dailyData, setDailyData] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isChart, setIsChart] = useState(false); // 모바일 상태 추가

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 데이터 조회 함수 정의
    const fetchStatistics = useCallback(async () => {
        try {
            setLoading(true);
            await getApiRequest(
                endpoint,
                {
                    'year': year,
                    'month': month
                },
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                (data) => {
                    setDailyData(data);
                },
                (errorMessage) => {
                    console.error('에러 메시지:', errorMessage);
                }
            );
        } catch (error) {
            console.error('주문 목록 요청 중 오류가 발생했습니다:', error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [year, month, token]);

    useEffect(() => {
        if (year && month) {
            fetchStatistics(); // year와 month가 있을 때만 데이터 조회
        }
    }, [fetchStatistics, year, month]);

    // 합계 계산 함수
    const calculateTotals = (dailyData) => {
        return dailyData.reduce(
            (acc, curr) => {
                acc.totalAmount += curr.total_amount || 0;
                acc.totalFund += curr.total_fund || 0;
                acc.totalRevenue += curr.revenue || 0;
                acc.totalPartnerRevenue += curr.partner_revenue || 0;
                return acc;
            },
            { totalAmount: 0, totalFund: 0, totalRevenue: 0, totalPartnerRevenue: 0 }
        );
    };

    const totals = calculateTotals(dailyData); // 합계 계산

    const days = Array.from({ length: 31 }, (_, i) => ({
        index: `${i + 1}일`,
        total_amount: 0,
        total_fund: 0,
        revenue: 0,
        partner_revenue: 0,
    }));

    dailyData.forEach(day => {
        const dayIndex = parseInt(day.date) - 1; // 일 인덱스 계산
        days[dayIndex] = {
            index: `${dayIndex + 1}일`,
            total_amount: day.total_amount || 0,
            total_fund: day.total_fund || 0,
            revenue: day.revenue || 0,
            partner_revenue: day.partner_revenue || 0,
        };
    });
    

    return (
        <>{!isChart && (
            <div className='daily-list-container'>
                <h1 className="daily-list-title">Daily Statistics</h1>
                <div className='daily-button'>
                    <button onClick={() => setIsChart(true)}>
                        차트보기
                    </button>
                    <button onClick={() => setIsDailyVisible(false)}>
                        창닫기
                    </button>
                </div>

                {isMobile ? (
                    <CardList datalist={days} totals={totals} totalTitle={`${month}월`}/>
                ) : (
                    <TableComponent datalist={days} totals={totals} totalTitle={`${month}월`}/>
                )}
            </div>)}
            {isChart && (
                <BarChart datalist={days} chartTitle={`${month}월`} setIsChart={setIsChart}/>
            )}
        </>
    );
}

export default Daily;
