import React from 'react';
import {formatAmount, formatDate} from '../utils/FormatChange'

const UserOrderTable = ({orders, handleSetIsOpen, handelsetOrder}) => {
    const veiwOrderDetail = (order) => {
        handleSetIsOpen(true)
        handelsetOrder(order)
    }
    return (
        <>
        <table className="order-list-table">
            <thead>
            <tr>
                <th>ID.</th>
                <th>주문 날짜</th>
                <th>사용자</th>
                <th>주문 아이디</th>
                <th>주문 수량</th>
                <th>주문 금액</th>
                <th>상태</th>
                <th>상세보기</th>
            </tr>
            </thead>
            <tbody>
            {orders.length > 0 ? (
                orders.map(order => (
                    <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{formatDate(order.created_at)}</td>
                        <td>{order.name_kor}</td>
                        <td>{order.order_id}</td>
                        <td>{formatAmount(order.amount)}</td>
                        <td>{formatAmount(order.total_fund)}</td>
                        <td>
                            <span className={`status status-${order.status.toLowerCase()}`}>
                            {order.status}
                            </span>
                        </td>
                        <td>
                            <button className="detail-button" onClick={() => veiwOrderDetail(order)}>
                                상세보기
                            </button>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="7">주문 내역이 없습니다.</td>
                </tr>
            )}
            </tbody>
        </table>
        </>
    );
};

export default UserOrderTable;
