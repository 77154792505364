// Statistics.jsx
import React from 'react';
import Monthly from '../components/statistics/Monthly';

function Statistics() {
    return (
            <Monthly/>
    );
}

export default Statistics;
