import { formatAmount } from "../utils/FormatChange";

const MidiumCardBox = ({ title, data }) => {
    return (
        <div className="cardbox">
            <div className="cardbox-title">{title}</div>
            <div className="cardbox-info">
                {data.map((info, index) => {
                    return <CardInfo key={index} value={info.value} label={info.label} />;
                })}
            </div>
        </div>
    );
};

const CardInfo = ({ value, label }) => {
    const displayValue = value ?? 0;
    return <div><span className="bold-content">{formatAmount(displayValue, 0)}</span><span className="sub-text"> { label }</span></div>
}

export default MidiumCardBox