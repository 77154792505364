import React, { useCallback, useEffect, useState } from 'react';
import UserOrderTable from "../components/orderhistory/UserOrderTable";
import useApiRequest from "../components/fetch/useApiRequest";
import useMediaQuery from "../components/customhook/useMediaQuery";
import UserOrderCard from "../components/orderhistory/UserOrderCard";
import UserOrderDetail from "../components/orderhistory/UserOrderDetail";
import Pagination from "../components/common/Pagination";

const UserOrderList = () => {
    const token = localStorage.getItem('access_token');
    const endpoint = '/admin/order/history';
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { getApiRequest } = useApiRequest();

    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // 총 페이지 수 상태

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const fetchOrders = useCallback(async () => {
        try {
            await getApiRequest(
                endpoint,
                { 'page': currentPage },
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                (data) => {
                    setOrders(data.orders); // API에서 가져온 주문 목록 설정
                    setTotalPages(data.total_pages); // API에서 총 페이지 수 설정
                },
                (errorMessage) => {
                    console.error('API 오류:', errorMessage);
                }
            );
        } catch (error) {
            console.error('주문 목록 요청 중 오류가 발생했습니다:', error);
        }
        // eslint-disable-next-line
    }, [token, currentPage]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    return (
        <div className="partner-detail-container" style={{ height: '80vh', overflowY: 'auto' }}>
            <h1 className="partner-list-title">User Order History</h1>
            <div className="order-list-container">
                {isMobile ? (
                    <UserOrderCard
                        orders={orders}
                        handleSetIsOpen={setIsOpen}
                        handelsetOrder={setOrder}
                    />
                ) : (
                    <UserOrderTable
                        orders={orders}
                        handleSetIsOpen={setIsOpen}
                        handelsetOrder={setOrder}
                    />
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    maxPageButtons={isMobile ? 3 : 10}
                />
            </div>
            {isOpen && <UserOrderDetail order={order} handleSetIsOpen={setIsOpen} fetchOrders={fetchOrders}/>}
        </div>
    );
};

export default UserOrderList;
